import React from 'react'
import { IResourceComponentsProps, useShow } from '@refinedev/core'
import {
  DeleteButton,
  EditButton,
  RefreshButton,
  Show,
  TextField,
} from '@refinedev/antd'
import { Image, List, Typography } from 'antd'
import { VenuesAdminRo } from '@/services/data-contracts'
import MDEditor from '@uiw/react-md-editor'

const { Title, Link } = Typography

export const VenuesShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<VenuesAdminRo>()
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      title="Просмотр Площадки"
      headerButtons={() => (
        <>
          <EditButton type="primary">Редактировать</EditButton>
          <DeleteButton>Удалить</DeleteButton>
          <RefreshButton>Перезагрузить</RefreshButton>
        </>
      )}
    >
      <Title level={5}>ID</Title>
      <TextField value={record?.id ?? ''} />
      <Title level={5}>ID Кассира</Title>
      <TextField value={record?.kassirId ?? ''} />
      <Title level={5}>Регион</Title>
      <TextField value={record?.region?.name ?? ''} />
      <Title level={5}>Название</Title>
      <TextField value={record?.name ?? ''} />
      <Title level={5}>Адрес</Title>
      <TextField value={record?.address ?? ''} />
      <Title level={5}>Ссылка</Title>
      <Link target="_blank" href={record?.url}>
        {record?.url ?? ''}
      </Link>
      <Title level={5}>Описание</Title>
      <MDEditor.Markdown
        source={record?.description}
        style={{
          whiteSpace: 'pre-wrap',
          background: 'transparent',
          color: 'black',
          marginBottom: 20,
        }}
      />
      <Title level={5}>Залы</Title>
      <List
        size="small"
        bordered
        dataSource={record?.halls}
        style={{ marginBottom: 25 }}
        renderItem={hall => (
          <List.Item>
            {hall?.name}
            <br />
            {hall?.address}
          </List.Item>
        )}
      />
      <Title level={5}>Контакты</Title>
      <List
        size="small"
        bordered
        dataSource={record?.contacts}
        style={{ marginBottom: 25 }}
        renderItem={contact => <List.Item>{contact}</List.Item>}
      />
      <Title level={5}>Социальные сети</Title>
      <List
        size="small"
        bordered
        dataSource={record?.socials}
        style={{ marginBottom: 25 }}
        renderItem={social => (
          <List.Item>{`${social.type} - ${social.link}`}</List.Item>
        )}
      />
      <Title level={5}>Дополнительная информация</Title>
      <List
        size="small"
        bordered
        dataSource={record?.moreInfo}
        style={{ marginBottom: 25 }}
        renderItem={info => <List.Item>{info}</List.Item>}
      />
      <Title level={5}>Параметры площадки</Title>
      <List
        size="small"
        bordered
        dataSource={record?.parameters}
        style={{ marginBottom: 25 }}
        renderItem={hall => (
          <List.Item>
            {hall?.name} - {hall?.value}
          </List.Item>
        )}
      />
      <Title level={5}>Постер</Title>
      <Image width={500} src={record?.poster ?? ''} />
    </Show>
  )
}
