import React, { useCallback, useEffect, useState } from 'react'
import {
  IResourceComponentsProps,
  BaseRecord,
  useSelect,
  HttpError,
  LogicalFilter,
  useGetIdentity,
} from '@refinedev/core'
import {
  useTable,
  List,
  EditButton,
  ShowButton,
  DeleteButton,
} from '@refinedev/antd'
import { Table, Space, Select, Input, Flex } from 'antd'
import { RegionRoDto, UserRoDto, VenuesDataRo } from '@/services/data-contracts'
import { SearchOutlined } from '@ant-design/icons'
import useDebounce from '@/scripts/debounce'

export const VenuesList: React.FC<IResourceComponentsProps> = () => {
  const [inputText, setInputText] = useState<string>()
  const debounced = useDebounce(inputText)

  const { data: user } = useGetIdentity<UserRoDto>()
  const isAdmin = user?.roles === 6

  const { tableProps, filters, setFilters } = useTable<
    VenuesDataRo,
    HttpError
  >()

  useEffect(() => {
    if (!isAdmin && user?.region.key) {
      setFilters([
        {
          field: 'regions',
          operator: 'eq',
          value: [user.region.key],
        },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, user?.region.key])

  useEffect(() => {
    if (debounced !== undefined) {
      setFilters(
        [
          {
            field: 'name',
            operator: 'eq',
            value: debounced?.trim(),
          },
        ],
        'merge',
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced])

  useEffect(() => {
    const input = (filters as LogicalFilter[]).find(
      filter => filter.field === 'name',
    )?.value

    setInputText(input)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { ...selectProps } = useSelect<RegionRoDto>({
    resource: 'regions',
    pagination: { mode: 'off' },
    filters: [],
    sorters: [],
    optionLabel: 'name',
    optionValue: 'key',
  })

  const searchHandler = useCallback(props => {
    setInputText(props.target.value)
  }, [])

  return (
    <List createButtonProps={{ children: 'Создать' }} title="Просмотр Площадок">
      <Flex gap="middle" style={{ marginBottom: 20 }}>
        {isAdmin && (
          <Select
            placeholder="Регион"
            style={{ width: 300 }}
            onChange={value => {
              setFilters(
                [
                  {
                    field: 'regions',
                    operator: 'eq',
                    value: [value],
                  },
                ],
                'merge',
              )
            }}
            {...selectProps}
          />
        )}
        <Input
          value={inputText}
          addonAfter={<SearchOutlined />}
          onChange={searchHandler}
          placeholder="Название Места проведения"
        />
      </Flex>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="kassirId" title="ID Кассира" />
        <Table.Column dataIndex="name" title="Название" />
        <Table.Column
          dataIndex="region"
          title="Регион"
          render={(value: RegionRoDto) =>
            value ? `${value.name}` : 'Не назначен'
          }
        />

        <Table.Column
          title="Управление"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
